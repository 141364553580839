<template>
    <div class="HomeWorkDetail">
        <div class="work-name">任务名称：{{ target.workName }}</div>
        <div class="work-time">任务时间：{{ target.beginTime }} ~ {{ target.endTime }}</div>
        <div class="work-type">任务类型：视频</div>
        <div class="work-class">制定班级：三年二班</div>
        <div class="work-material">素材下载：<el-link type="primary" :underline="false" @click="download">立即下载</el-link></div>
        <div class="work-task">
            提交任务：
            <div class="upload" @click="openFile">
                <i class="iconfont">&#xe62e;</i>
                点击选择或移动文件至此处
            </div>
            <div class="select-input">{{ value }}</div>
        </div>
        <el-button class="submit" type="primary" @click="back">提交</el-button>
        <input v-show="false" ref="refFile" type="file" @change="fileChange">
    </div>
</template>

<script>
export default {
    name: 'HomeWorkDetail',
    components: {},
    props: {},
    data() {
        return {
            list: [{
                index: 1,
                workName: "设计一个短视频推广",
                status: `进行中`,
                classSize: 20,
                createTime: "2023-03-22",
                beginTime: "2023-03-22",
                endTime: "2023-04-30",
            }, {
                index: 2,
                workName: "画中画剪辑实训",
                status: `进行中`,
                classSize: 20,
                createTime: "2023-02-20",
                beginTime: "2023-02-20",
                endTime: "2023-04-30",
            }, {
                index: 3,
                workName: "美妆类短视频脚本设计实训",
                status: `已结束`,
                classSize: 20,
                createTime: "2023-02-08",
                beginTime: "2023-02-08",
                endTime: "2023-02-20",
            }],
            value: ""
        }
    },
    computed: {
        target() {
            let id = this.$route.query.id * 1 - 1;
            !id && (id = 0);
            return this.list[id];
        }
    },
    methods: {
        download() {
            window.open('http://zblive.e-class.me/uploads/system/script.pdf')
        },
        openFile() {
            const file = this.$refs.refFile;
            if(!file) return;
            file.click();
        },
        fileChange(e) {
            const file = e.target.files[0];
            if(!file) return;
            this.value = file.name;
        },
        back() {
            this.$router.back();
        }
    }
}
</script>

<style scoped lang="scss">
.HomeWorkDetail {
    box-sizing: border-box;
    height: 100%;
    padding: 75px 181px;
    [class^='work'] {
        margin-bottom: 16px;
        &.work-name {
            margin-bottom: 50px;
        }
        &.work-material,
        &.work-task {
            margin-bottom: 40px;
        }
        &.work-material {
            display: flex;
            align-items: center;
        }
        &.work-task .upload {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 400px;
            height: 160px;
            margin: 10px 0;
            color: #aaa;
            background-color: #f5f7fa;
            border: 1px solid #ddd;
            border-radius: 4px;
            cursor: pointer;
            transition: border-color .3s;
            &:hover {
                border-color: #2821fc;
            }
            .iconfont {
                transform: rotate(-90deg);
                margin-bottom: 10px;
            }
        }
    }
    .submit {
        width: 108px;
    }
}
</style>
